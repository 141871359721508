<template>
  <div>
    <base-form-item :field="field">
      <div>{{ value }} %</div>
      <el-slider
          :step="10"
          disabled
          v-model="value"
      />
    </base-form-item>
  </div>
</template>

<script>
import BaseFormItem from '../../UI/FormItem.vue';
import abstractForm from '../../mixin/index';

export default {
  mixins: [abstractForm],
  name: 'FieldProgressBar_index',
  components: {
    BaseFormItem,
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>

</style>
